export const questions = [
    {
      id: 1,
      question: "Why do I need it?",
      answer: "Our project can help you to boost your posts with a fresh batch of authentic comments and bring you to Instagram trends. Lots of comments are undeniable proof of your popularity and that's exactly what we deliver. There is a golden rule of promotion: the more comments you have on Instagram - the more advertisers and new followers you attract.",
    },
  
    {
      id: 2,
      question: "Does this work with Wordpress?",
      answer: "Does this work with Wordpress?",
    },
    {
      id: 3,
      question: "Does this work with Wordpress?",
      answer: "Մենք առաջարկում ենք՝ ցհվսհցյվակհդցբկդբցսբդցյբադցադյցհդհց",
    },
    {
      id: 4,
      question: "What is your refund policy?",
      answer: "Our project can help you to boost your posts with a fresh batch of authentic comments and bring you to Instagram trends. Lots of comments are undeniable proof of your popularity and that's exactly what we deliver. There is a golden rule of promotion: the more comments you have on Instagram - the more advertisers and new followers you attract.",
    },
  ];